import service from '@/store/services/service';
import axios from 'axios'; // the axios instance created in http.js is for protected APIs
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import * as XLSX from 'xlsx/xlsx.mjs';
import i18n from '../lang/index';
import { notify, notifyError, notifySuccess } from './index';

/**
 * Read local file
 * @param {*} data
 * @returns
 */
export const base64Encode = (data) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(data)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

/**
 * @param {string} name Exported filename
 * @param {string} url A short api url to fetch data
 * @param {object | Array<object>} params Params for fetcher, or data if no pagination
 * @param {Function} dataProcess Format or delete some data after every fetch request
 * @param {Number} total Number of total rows
 * @param {Number} perPage number of rows for every fetch request, recommended value 1000
 * @param {Array<string>} headings Data column heading (in order)
 * @param {Array<Array<string>>} headingsFormat Format (translation) for data column heading
 */
export async function exportAsExcel(name, url, params, total, dataProcess, headings, headingsFormat, setIsLoading) {
  if (!total || total === 0) {
    notify('warn', i18n.t('notify.no_records'))
    return
  }
  setIsLoading(true)
  try {
    const queryParams = { ...params }
    const noPagination = url === null // no need to fetch data from url because no pagination

    var allData = []
    var cnt = 0
    while (cnt < total) {
      var fetched = []
      if (!noPagination) {
        const data = await service.generalFetcher(url, queryParams).then((res) => {
          if (res.status === 200) {
            total = res.data.data.total
            return res.data.data.data // array
          }
        })
        fetched = data
        queryParams.page++
      } else {
        fetched = params // pass data as params because no pagination
      }

      // Data pre-process can append additional data (rows) into the 'allData' container
      // It will not affect the 'cnt' value which is the while loop stopper
      if (dataProcess != null) {
        var processed = dataProcess(fetched)
        if (processed && Array.isArray(processed) && processed.length > 0) {
          allData.push.apply(allData, processed)
        } else {
          allData.push.apply(allData, fetched)
        }
      } else {
        allData.push.apply(allData, fetched)
      }

      cnt += fetched.length // while loop stopper
    }
    if (allData.length == 0) {
      notify('warn', i18n.t('notify.no_records'))
    } else {
      var workbook = XLSX.utils.book_new()
      var workSheet
      if (headings && headingsFormat) {
        workSheet = XLSX.utils.aoa_to_sheet(headingsFormat)
        XLSX.utils.sheet_add_json(workSheet, allData, {
          header: headings,
          skipHeader: true,
          origin: -1,
        })
      } else {
        workSheet = XLSX.utils.json_to_sheet(allData)
      }
      XLSX.utils.book_append_sheet(workbook, workSheet, 'Sheet1')
      let fileName = name + '.xlsx'
      XLSX.writeFile(workbook, fileName)
    }
    setIsLoading(false)
    return
  } catch (e) {
    notifyError(e, i18n.t('notify.export_excel_failed'))
    setIsLoading(false)
    return
  }
}

/**
 * @param {Array} urls
 * @param {String} zipFilename The name of the zip file, 'Invoices.zip' as default
 * @param {Function} setIsLoading
 */
export function exportAsZip(urls, zipFilename = 'Invoices.zip', setIsLoading, fileNames = []) {
  setIsLoading(true)
  const controller = new AbortController()
  var zip = new JSZip()
  var count = 0
  var isAborted = false
  urls.forEach((url, i) => {
    var filename = urls[i]
    filename = filename.slice(filename.lastIndexOf('/') + 1)
    if(i < fileNames.length)
      filename = fileNames[i]
    // load files (blob) from urls and save in a zip file
    axios({
      url: url,
      method: 'GET',
      responseType: 'blob', // important
      signal: controller.signal,
    })
      .then((res) => {
        zip.file(filename, res.data, { binary: true })
        count++
        if (count == urls.length) {
          zip.generateAsync({ type: 'blob' }).then(function (base64) {
            saveAs(base64, zipFilename)
            setIsLoading(false)
            notifySuccess(res, i18n.t('notify.success'))
          })
        }
      })
      .catch((err) => {
        controller.abort()
        setIsLoading(false)
        if (!isAborted) {
          isAborted = true
          notifyError(err, i18n.t('notify.unknown_err'))
        }
        throw err
      })
  })
}

/**
 * @param {Array} blobs
 * @param {String} zipFilename The name of the zip file, 'Invoices.zip' as default
 * @param {Function} setIsLoading
 */
export async function exportAsZipWithBlob(blobs, zipFilename, setIsLoading) {
  setIsLoading(true)
  var zip = new JSZip()
  var count = 0
  for (const i of blobs) {
    var item = await i
    var filename = item.filename.slice(item.filename.lastIndexOf('/') + 1)
    zip.file(filename, item.blob, { binary: true })
    count++
    if (count == blobs.length) {
      zip.generateAsync({ type: 'blob' }).then(function (base64) {
        saveAs(base64, zipFilename)
        setIsLoading(false)
        notifySuccess({ status: 200, config: { method: 'GET' }, data: { message: 'download files' } }, i18n.t('notify.success'))
      })
    }
  }
}
